<template>
  <section class="slothutility">
    <h1 class="text-white">SlothUtility</h1>

    <div class="description">
      <p>
        Save tonnes of time by updating your Gas, Electricity or Water reading with your suppliers
        in just a few clicks. Update your providers as regularly as you like, but once a month is
        recommended ⚡
      </p>
    </div>

    <form class="miniform mt-3" @submit.prevent>
      <div class="text-center mb-2">
        <img
          :src="`${urlBucket}/dashboard/slothutility-header-miniform.png`"
          class="img-fluid"
          width="335px"
          alt=""
        />
      </div>

      <p class="text-white font-weight-bolder">Which meter reading would you like to submit?</p>

      <div class="selection row mb-2">
        <div class="col" style="padding: 4px 8px">
          <div
            class="d-flex align-items-center justify-content-center squares hvr hvr-radial-out"
            :class="{ selected: currentInfo.type == 'Gas' }"
            @click="() => selectTypeSupplier('Gas')"
          >
            <div>
              <img :src="`${urlBucket}/form/assets/homecare.png`" class="img-fluid" />
              <p class="mb-0 text-black">Gas</p>
            </div>
          </div>
        </div>
        <div class="col" style="padding: 4px 8px">
          <div
            class="d-flex align-items-center justify-content-center squares hvr hvr-radial-out"
            :class="{ selected: currentInfo.type == 'Electricity' }"
            @click="() => selectTypeSupplier('Electricity')"
          >
            <div>
              <img
                :src="`${urlBucket}/form/assets/electricity.png`"
                style="width: 60px"
                class="img-fluid"
              />

              <p class="mb-0 text-black">Electricity</p>
            </div>
          </div>
        </div>

        <div class="col" style="padding: 4px 8px">
          <div
            class="d-flex align-items-center justify-content-center squares hvr hvr-radial-out"
            :class="{ selected: currentInfo.type == 'Water' }"
            @click="() => selectTypeSupplier('Water')"
          >
            <div>
              <img :src="`${urlBucket}/form/assets/water.svg`" class="img-fluid" />

              <p class="mb-0 text-black">Water</p>
            </div>
          </div>
        </div>
      </div>
      <p
        v-if="errors.type"
        class="mt-1 mb-0 text-left question-error animate__animated animate__shakeX"
      >
        * This field is required
      </p>

      <div class="mb-2 question">
        <p class="text-left font-weight-bolder">Select your supplier?</p>
        <vSelect
          class="w-100 select"
          label="title"
          :clearable="false"
          :options="currentInfo.type == 'Water' ? waterSuppliers : energySuppliers"
          :placeholder="'Select your supplier?'"
          :multiple="false"
          @input="(e) => {}"
          v-model="currentInfo.supplier"
        >
          <!-- <template slot="option" slot-scope="option">
                {{ option.title ? option.title : option }}
              </template> -->
        </vSelect>
        <p
          v-if="errors.supplier"
          class="mt-1 mb-0 text-left question-error animate__animated animate__shakeX"
        >
          * This field is required
        </p>
      </div>

      <!-- Number input -->
      <div class="mb-2 question">
        <p class="text-left font-weight-bolder">Enter your meter reading?</p>

        <input
          type="number"
          :placeholder="'Enter your meter reading?'"
          class="w-100 p-1"
          v-model="currentInfo.meterReading"
        />
        <p
          v-if="errors.meterReading"
          class="mt-1 mb-0 text-left question-error animate__animated animate__shakeX"
        >
          * This field is required
        </p>
      </div>

      <!-- Input Text -->
      <div class="mb-2 question">
        <p class="text-left font-weight-bolder">Which date was this taken on?</p>

        <DateMultiSelect
          :dateModel="currentInfo['readDate']"
          @formattedDate="(value) => (currentInfo['readDate'] = value)"
          :isDOB="false"
          ref="dateMultiSelect"
        />

        <p
          v-if="errors.readDate"
          class="mt-1 mb-0 text-left question-error animate__animated animate__shakeX"
        >
          * This field is required
        </p>
      </div>

      <div
        class="label d-flex align-items-center mt-1 px-2 animate__animated animate__pulse"
        v-if="submitted"
      >
        <img :src="`${urlBucket}/dashboard/check-y.png`" width="30px" alt="" class="mr-1" />
        <p class="ms-1 mb-0">Excellent, meter reading submitted</p>
      </div>

      <button
        class="button mt-3 d-flex align-items-center justify-content-center"
        :disabled="loading"
        @click="submitSlothUtilities()"
      >
        <p class="mb-0 font-weight-bolder mr-1" style="font-size: 18px">Submit</p>
        <div class="spinner-border text-light" role="status" v-if="loading"></div>
      </button>
    </form>

    <div class="history ml-3 pb-5" v-if="records.length > 0">
      <h1 class="font-weight-bolder mt-4 mb-2" style="color: #ffe859">History</h1>

      <div v-for="(record, index) in records" :key="index" class="item mb-3">
        <p>{{ record["dateTitle"] }} ✅</p>
        <div v-for="service in record.services" :key="service.id">
          <p class="mb-0" >{{ service.supplier }}</p>
          <p>{{ service.service }}: {{ service["meterReading"] }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import DateMultiSelect from "../ecommerce/DateMultiSelect.vue";

import { mapState, mapGetters } from "vuex";
import axiosCustom from "@axios";
import Vue from "vue";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { energySuppliers, waterSuppliers } from "../lists.js";

export default {
  name: "SlothUtility",
  components: {
    vSelect,
    DateMultiSelect,
  },
  data() {
    return {
      loading: false,
      optionsSuppliers: [],
      energySuppliers,
      waterSuppliers,
      currentInfo: {
        type: "",
        supplier: null,
        meterReading: null,
        readDate: null,
      },
      info: {
        email: JSON.parse(localStorage.getItem("userData")).email,
        readings: [
          { type: "Gas", supplier: null, meterReading: null, readDate: null },
          { type: "Electricity", supplier: null, meterReading: null, readDate: null },
          { type: "Water", supplier: null, meterReading: null, readDate: null },
        ],
      },
      errors: {
        type: false,
        supplier: false,
        meterReading: false,
        readDate: false,
      },
      submitted: false,
      records: [],
    };
  },
  mounted() {
    let readings = Object.assign({ ...this.lastMove.admin.readings });
    console.log(this.getHistory(readings));
    this.records = this.getHistory(readings);
  },
  methods: {
    selectTypeSupplier(type) {
      //saving current changes
      const idType = this.info.readings.findIndex((e) => e.type == this.currentInfo.type);
      this.info.readings[idType] = this.currentInfo;

      //change to new type
      const selected = this.info.readings.find((element) => element.type == type);
      this.currentInfo = selected;

      // console.log(selected)
      this.clearErrors();

      //Set supplier previous selected
      const { selectedEnergySupplier, selectedGasSupplier } = this.lastMove.services;

      if (type == "Gas") this.currentInfo.supplier = selectedGasSupplier;
      if (type == "Electricity") this.currentInfo.supplier = selectedEnergySupplier;
      if (type == "Water") this.currentInfo.supplier = this.lastMove.councils["newWater"];
    },
    submitSlothUtilities() {
      if (this.hasErrors()) {
        return;
      }
      const deleteReadingsWithoutInfo = (obj) => {
        for (let key in obj) {
          if (obj[key] === null || obj[key] === "") {
            return false;
          }
        }
        return true;
      };

      let body = Object.assign({ ...this.info });
      body.readings = body.readings.filter((e) => deleteReadingsWithoutInfo(e));

      console.log(body);

      // return;
      this.loading = true;
      this.submitted = false;
      axiosCustom
        .post(`/dashboard/slothUtilities`, body)
        .then((result) => {
          this.submitted = true;
          console.log(result.data);

          //refreshing ticket history
          axiosCustom.get(`api/user/getDetails?email=${this.user.email}`).then(({ data }) => {
            let readings = data.moves[data.moves.length - 1].admin.readings;
            this.records = this.getHistory(readings);
          });
          //refreshing ticket history
        })
        .catch((err) => {})
        .finally(() => (this.loading = false));
    },
    hasErrors() {
      this.errors.type = !this.currentInfo.type ? true : false;
      this.errors.supplier = !this.currentInfo.supplier ? true : false;
      this.errors.meterReading = !this.currentInfo.meterReading ? true : false;
      this.errors.readDate = !this.currentInfo.readDate ? true : false;

      return Object.values(this.errors).includes(true);
    },
    clearErrors() {
      for (const [key, value] of Object.entries(this.errors)) {
        this.errors[key] = false;
      }
    },
    getHistory(data) {
      const formatDate = (date) => {
        let options = { day: "numeric", month: "long", year: "numeric" };
        let dateString = date.toLocaleDateString("en-GB", options);
        let day = date.getDate();
        let suffix = "th";
        if (day === 1 || day === 21 || day === 31) {
          suffix = "st";
        } else if (day === 2 || day === 22) {
          suffix = "nd";
        } else if (day === 3 || day === 23) {
          suffix = "rd";
        }
        let dayWithSuffix = day + suffix;
        return dateString.replace(/\d+/, dayWithSuffix);
      };

      let result = [];
      let recordsByDate = {};
      for (let service of ["gas", "water", "electricity"]) {
        for (let record of data[service]) {
          let date = new Date(record.readDate /* record.creationDate */);
          let dateString = date.toLocaleDateString();

          if (!recordsByDate[dateString]) {
            recordsByDate[dateString] = {
              date,
              dateTitle: formatDate(date),
              dateString: dateString,
              services: [],
            };
          }

          recordsByDate[dateString].services.push({
            id: Math.random().toString(36).substring(2, 18), // unique id for v-for
            supplier: record['supplier'],
            service: service.replace(/^\w/, (c) => c.toUpperCase()), //capitalize string service
            meterReading: record.meterReading,
          });
        }
      }
      for (let dateString in recordsByDate) {
        result.push(recordsByDate[dateString]);
      }
      result.sort((a, b) => a.date - b.date);
      return result;
    },
  },
  computed: {
    ...mapState("app", ["user", "urlBucket", "currentMove", "logoPartner", "lastMove"]),
    ...mapGetters("app", ["isAPartner", "colorPartner"]),
  },
  watch: {
    // currentInfo: {
    //   handler(obj) {
    //     console.log(obj.type);
    //     this.optionsSuppliers = []
    //     this.optionsSuppliers = obj.type == "Water" ? waterSuppliers : energySuppliers;
    //   },
    //   deep: true,
    // },
  },
};
</script>

<style lang="scss">
.slothutility {
  margin-left: 12em;

  @media screen and (max-width: 900px) {
    margin-left: 0;
  }

  h1 {
    font-weight: bold;
  }

  p {
    color: white;
    font-size: 16px;
  }

  .miniform,
  .description {
    max-width: 550px;
    text-align: justify;
  }

  .description p {
    line-height: 1.7;
  }

  .miniform {
    border: 5px solid #fcf478;
    border-radius: 30px;
    background-color: #212534;
    padding: 27px 40px;
    max-width: 550px;

    .selection {
      padding-left: 9px;

      .col {
        max-width: 125px;
        min-width: 125px;
        padding: 8px;
        padding-bottom: 13px;

        :hover {
          color: white !important;
        }
      }

      .squares {
        text-align: center;
        background: #fff;
        border-radius: 5px;
        cursor: pointer;
        color: #000;
        box-shadow: 0 1px 7px rgba(11, 45, 150, 0.2);
        transition: all 0.1s ease-in-out;
        padding: 7px;

        img {
          width: 70px;
          height: 67px;
          margin-bottom: 6px;
        }

        p {
          color: #000;
          font-weight: 600;
        }
      }

      .selected {
        background-color: #00b9ee;

        p {
          color: white;
        }
      }
    }

    p {
      color: white;
    }

    input[type="text"],
    input[type="number"] {
      outline: none;
      border-radius: 0.375rem;
      border: none;
      color: black;
      font-size: 16px;
      font-size: 16px;
    }

    .question-error {
      font-size: 14px;
      color: #c64a45;
    }

    .select {
      font-size: 16px;

      .vs__selected {
        color: black;
      }

      .vs__dropdown-toggle {
        padding: 0.6rem !important;
        padding-top: 0.75rem !important;
        padding-bottom: 0.75rem !important;
        border-radius: 0.375rem;
        color: black;
        background-color: white;
      }

      .vs__dropdown-menu {
        color: black !important;
      }
    }

    .label {
      background-color: rgba(57, 61, 74, 0.62);
      border-radius: 10px;
      padding: 6px;
    }

    button {
      all: unset;
      box-sizing: border-box;
      width: 100%;
      display: block;
      padding: 15px;
      color: white;
      text-align: center;
      letter-spacing: 1.5px;
      font-weight: bold;
      cursor: pointer;
      border-radius: 13px;
      font-size: 16px;
    }

    .button {
      background-color: #ec0677;
      box-shadow: 0 5px 0 #7d0741;
    }

    .button:active {
      box-shadow: none;
      transform: translateY(5px);
    }
  }
}
</style>
